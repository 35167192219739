<template>
  <b-row>
    <b-col cols="12">
      <edit-lease-wizard-vertical :leaseId="leaseId" />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import EditLeaseWizardVertical from "@/views/forms/components/form-leases/EditLeaseWizardVertical.vue";
// import { sync } from "vuex-pathify";

export default {
  computed: {
    // user: sync("user/profile"),
    // leases: sync("leases/list"),
    leaseId() {
      // console.log("leaseid: ", this.$router);
      let id = parseInt(this.$route.params.leaseId);
      if (id) {
        return id;
      } else {
        // new lease
        return -1;
      }
    },
  },
  mounted() {
    console.log("edit lease id:", this.$route.params.leaseId);
  },
  data() {
    return {
      // lease: null,
    };
  },
  components: {
    BRow,
    BCol,
    EditLeaseWizardVertical,
    // FormWizardNumber,
    // FormWizardIcon,
    // FormWizardVertical,
    // FormWizardVerticalIcon,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
