<template>
  <b-row class="bottom_space">
    <b-col cols="12" class="mb-2">
      <h5 class="mb-0">
        {{ $t("Upload Contracts") }}
      </h5>
      <small class="text-muted">
        {{ $t("Upload All Documents") }}
      </small>
    </b-col>

    <b-col cols="12">
      <b-form-file
        :placeholder="$t('Choose Files')"
        drop-placeholder="$t('Drop Files')"
        multiple
        size="lg"
        ref="file-input"
        v-model="files"
        @input="fileSelected"
      />
    </b-col>

    <b-col cols="12" class="mt-2">
      <h5>
        {{ $t("Selected files") }}
        <b-badge pill variant="primary">
          {{ files.length }}
        </b-badge>
      </h5>
      <hr />
    </b-col>

    <b-col cols="12">
      <b-list-group>
        <b-list-group-item
          v-for="file in files"
          :key="file.name"
          v-show="file.uploaded != 'success'"
        >
          {{ file.name }}
        </b-list-group-item>
      </b-list-group>
    </b-col>

    <b-col cols="12">
      <b-button
        class="my-1"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        @click="uploadFiles"
      >
        <feather-icon icon="UploadIcon" class="mr-50" />
        <span class="align-middle">
          {{ $t("Upload") }}
        </span>
      </b-button>
    </b-col>

    <b-col cols="12" class="mt-2">
      <h5>{{ $t("Uploaded") }}</h5>
      <hr />
    </b-col>

    <b-col cols="12">
      <b-list-group>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
          v-for="file in uploaded"
          :key="file.name"
        >
          <span class="mr-1 text-success">
            <feather-icon icon="CheckCircleIcon" size="24" />
          </span>
          {{ getNameFromFileObj(file) }}

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="btn-icon rounded-circle btn-sm"
            @click="deleteFile(file)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-list-group-item>
      </b-list-group>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Ripple from "vue-ripple-directive";
import laravel from "@/libs/laravel";
import { get, sync, call } from "vuex-pathify";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { delArrElem } from "@/libs/helpers";

import {
  BBadge,
  BListGroup,
  BListGroupItem,
  BFormFile,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BButton,
} from "bootstrap-vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BFormTextarea,
    BCardText,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BBadge,
    BButton,

    BListGroup,
    BListGroupItem,

    vSelect,
  },
  props: {
    uploaded: {
      type: Array,
      required: true,
    },
    leaseId: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    user_files: sync("user/files"),
  },

  mounted() {
    // few tests
    // console.log("1651316718_bitsimply_icon.png".match(/\d+_(.*)/i))
    // let arr = [{ id: 1 }, { id: 2 }, { id: 3 }];
    // delArrElem(arr, { id: 3 }, "id");
    // console.log(arr);
  },
  data() {
    return {
      files: [],

      // list of files that has been successfully uploaded
      // uploaded: [],
    };
  },
  methods: {
    deleteFile(file) {
      console.log("delete file: ", file);
      laravel.files
        .destroy(file)
        .then((resp) => {
          // remove from array
          // console.log("before del uplaoded: ", this.uploaded);
          // console.log("before del user_files: ", this.user_files);
          delArrElem(this.uploaded, file, "id");
          delArrElem(this.user_files, file, "id");
          // delArrElem(this.files, file, "id")
          this.files = [];
          // console.log("after del uplaoded: ", this.uploaded);
          // console.log("after del user_files: ", this.user_files);
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Delete error: " + error.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    getNameFromFileObj(file) {
      console.log("getNameFromFileObj:", file);
      return file.filename.match(/\d+_(.*)/i)[1];
    },
    uploadFiles() {
      console.log("upload files: ", this.files);
      for (let i = 0; i < this.files.length; i++) {
        console.log("file: ", this.files[i]);
        if (!this.files[i].uploaded) {
          laravel.files
            .upload(this.files[i], this.leaseId, "")
            .then((resp) => {
              console.log("success upload: ", resp);
              this.user_files.push(resp.data);
              console.log("user_files defined");
              this.uploaded.push(resp.data);
              console.log("uploaded defined");
              this.files[i].uploaded = "success";
            })
            .catch((error) => {
              console.log("error upload: ", error.message);
              this.files[i].uploaded = "error";
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Upload err: " + this.files[i].name,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            });
        }
      }
    },
    fileSelected(event) {
      // console.log("event: ", event);
      // console.log("files: ", this.files);
      this.$emit("file-selected", this.files);
    },
  },
};
</script>

<style lang="scss">
.bottom_space {
  margin-bottom: 2.5em;
}
</style>