<template>
  <b-row class="mb-2">
    <b-col cols="12" class="mb-2">
      <h5 class="mb-0">
        {{ $t("Other Terms") }}
      </h5>
      <small class="text-muted">
        {{ $t("Lease agreement terms") }}
      </small>
    </b-col>
    <!-- 
      <b-col md="4">
        <b-form-group
          :label="$t('Lease Type')"
          label-for="item-lease-type"
        >
          <b-form-select
            id="item-type"
            v-model="terms.type"
            :options="$t('leaseTermTypeList')"
            size=""
            class=""
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          :label="$t('Lease Start')"
          label-for="v-lease-start"
        >
          <b-form-datepicker
            id="v-lease-start"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="en"
            v-model="terms.start_date"
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          :label="$t('Lease End')"
          label-for="v-lease-end"
        >
          <b-form-datepicker
            id="v-lease-end"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="en"
            v-model="terms.end_date"
          />
        </b-form-group>
      </b-col>


      <b-col md="4">
        <b-form-group
          :label="$t('Payment Frequency')"
          label-for="item-paym-freq"
        >
          <b-form-select
            id="item-paym-freq"
            v-model="terms.paym_freq"
            :options="$t('leasePaymentFreqList')"
            size=""
            class=""
          />
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          :label="$t('Lease Amount')"
          label-for="v-lease-amount"
        >
          <b-form-input
            v-model="terms.amount" 
            id="v-lease-amount"
            type="number"
            placeholder=""
          />
        </b-form-group>
      </b-col>


      <b-col md="4">
        <b-form-group
          :label="$t('Bill Day')"
          label-for="v-bill-day"
        >
          <b-form-select
            id="v-bill-day"
            v-model="terms.billing_day"
            :options="$t('leaseBillDayList')"
            size=""
            class=""
          />
        </b-form-group>
      </b-col>



      <b-col md="4">
        <b-form-group
          :label="$t('Bond')"
          label-for="v-bond"
        >
          <b-form-input
            v-model="terms.bond" 
            id="v-bond"
            type="number"
            placeholder=""
          />
        </b-form-group>
      </b-col> -->

    <b-col cols="12" class="mt-2">
      <h5>{{ $t("Prepayment") }}</h5>
      <hr />
    </b-col>

    <b-col md="6">
      <b-form-group
        :label="$t('Prepayment Type')"
        label-for="v-prepayment-type"
      >
        <b-form-select
          id="v-prepayment-type"
          v-model="terms.prepay_type"
          :options="$t('leasePrepaymentTypeList')"
          size=""
          class=""
        />
      </b-form-group>
    </b-col>

    <b-col md="6">
      <b-form-group
        :label="$t('Prepayment Value')"
        label-for="v-prepayment-value"
      >
        <b-form-input
          v-model="terms.prepay_value"
          type="number"
          id="v-prepayment-value"
          placeholder=""
        />
      </b-form-group>
    </b-col>

    <b-col cols="12" class="mt-2">
      <h5>{{ $t("Late Payment") }}</h5>
      <hr />
    </b-col>

    <b-col md="4">
      <b-form-group
        :label="$t('Late Payment Type')"
        label-for="v-late-payment-type"
      >
        <b-form-select
          id="v-late-payment-type"
          v-model="terms.latepay_type"
          :options="$t('leaseLatePaymentTypeList')"
          size=""
          class=""
        />
      </b-form-group>
    </b-col>

    <b-col md="4">
      <b-form-group :label="$t('Late Interest')" label-for="v-late-interest">
        <b-form-input
          v-model="terms.latepaym_interest"
          type="number"
          id="v-late-interest"
          placeholder=""
        />
      </b-form-group>
    </b-col>

    <b-col md="4">
      <b-form-group :label="$t('Late Penalty')" label-for="v-latepaym-penalty">
        <b-form-input
          v-model="terms.latepay_value"
          id="v-latepaym-penalty"
          placeholder=""
        />
      </b-form-group>
    </b-col>

    <b-col cols="12" class="mt-2">
      <h5>{{ $t("Rent Increase") }}</h5>
      <hr />
    </b-col>

    <b-col md="6">
      <b-form-group :label="$t('Increase Type')" label-for="v-increase-type">
        <b-form-select
          id="v-increase-type"
          v-model="terms.increaseType"
          :options="$t('leaseIncreaseTypeList')"
          size=""
          class=""
        />
      </b-form-group>
    </b-col>

    <b-col md="6">
      <b-form-group :label="$t('Increase Value')" label-for="v-increase-value">
        <b-form-input
          v-model="terms.increaseValue"
          type="number"
          id="v-increase-value"
          placeholder=""
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import {
  BFormDatepicker,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BCardText,
} from "bootstrap-vue";

export default {
  components: {
    BFormDatepicker,
    BFormSelect,
    BFormTextarea,
    BCardText,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,

    vSelect,
  },
  props: {
    terms: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
