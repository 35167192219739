<template>
  <div>
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      :finish-button-text="$t('Save')"
      back-button-text="Previous"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
      @on-change="stepChanged"
    >
      <!-- account datails tab -->
      <tab-content :title="$t('General')">
        <b-row>
          <b-col cols="12" class="mb-2 d-flex justify-content-between">
            <h5 class="mb-0">
              {{ $t("General Info") }}
              <small class="text-muted">
                {{ $t("Enter general information") }}
              </small>
            </h5>
            <b-button
              v-if="leaseId <= 0"
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              pill
              size="sm"
              @click="resetForm()"
            >
              <feather-icon icon="WindIcon" class="mr-0" />
              {{ $t("Clear Form") }}
            </b-button>
          </b-col>

          <b-col md="6">
            <b-form-group
              :label="$t('Type of Property')"
              label-for="v-property-type"
            >
              <b-form-select
                id="v-property-type"
                v-model="leaseFormData.lease.property_type"
                :options="$t('propertyTypeList')"
                size=""
                class=""
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group :label="$t('Type of Lease')" label-for="v-lease-type">
              <b-form-select
                id="v-lease-type"
                v-model="leaseFormData.lease.lease_type"
                :options="$t('leaseTypeList')"
                size=""
                class=""
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group :label="$t('Address')" label-for="v-lease-adress">
              <b-form-input
                id="v-lease-address"
                placeholder="Address"
                v-model="leaseFormData.lease.address"
                rows="1"
              />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-textarea
              id="v-asset-description"
              placeholder="Description"
              v-model="leaseFormData.lease.description"
              rows="2"
            />
          </b-col>

          <b-col cols="12" class="mt-2">
            <h5>{{ $t("Terms") }}</h5>
            <hr />
          </b-col>

          <b-col md="4">
            <b-form-group :label="$t('Lease Type')" label-for="item-lease-type">
              <b-form-select
                id="item-type"
                v-model="leaseFormData.lease.terms_type"
                :options="$t('leaseTermTypeList')"
                size=""
                class=""
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group :label="$t('Lease Start')" label-for="v-lease-start">
              <b-form-datepicker
                id="v-lease-start"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="en"
                v-model="leaseFormData.lease.start_date"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group :label="$t('Lease End')" label-for="v-lease-end">
              <b-form-datepicker
                id="v-lease-end"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="en"
                v-model="leaseFormData.lease.end_date"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              :label="$t('Payment Frequency')"
              label-for="item-paym-freq"
            >
              <b-form-select
                id="item-paym-freq"
                v-model="leaseFormData.lease.paym_freq"
                :options="$t('leasePaymentFreqList')"
                size=""
                class=""
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              :label="$t('Lease Amount')"
              label-for="v-lease-amount"
            >
              <b-form-input
                v-model="leaseFormData.lease.paym_freq_amt"
                id="v-lease-amount"
                type="number"
                placeholder=""
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group :label="$t('Bill Day')" label-for="v-bill-day">
              <b-form-select
                id="v-bill-day"
                v-model="leaseFormData.lease.billing_day"
                :options="$t('leaseBillDayList')"
                size=""
                class=""
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group :label="$t('Bond')" label-for="v-bond">
              <b-form-input
                v-model="leaseFormData.lease.bond"
                id="v-bond"
                type="number"
                placeholder=""
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- upload tab -->
      <tab-content :title="$t('Upload')">
        <form-upload-lease-docs
          :uploaded.sync="leaseFormData.lease.documents"
          :leaseId="leaseFormData.lease.id"
        />
      </tab-content>

      <!-- address tab -->
      <!-- <tab-content :title="$t('Address')">
        <form-address :address.sync="leaseFormData.general.address" />
      </tab-content> -->

      <!-- leasors tab -->
      <tab-content :title="$t('Leasors')">
        <form-entities
          :entities.sync="leaseFormData.lease.leasors"
          :allowSelf="true"
          :user="user"
          :title="$t('Leasors')"
          :subtitle="$t('Enter leasors information')"
        />
      </tab-content>

      <!-- leasees tab -->
      <tab-content :title="$t('Leasees')">
        <form-entities
          :entities.sync="leaseFormData.lease.leasees"
          :allowSelf="false"
          :title="$t('Leasees')"
          :subtitle="$t('Enter leasees information')"
        />
      </tab-content>

      <!-- guarantor tab -->
      <tab-content :title="$t('Guarantors')">
        <form-entities
          :entities.sync="leaseFormData.lease.guarantors"
          :allowSelf="false"
          :title="$t('Guarantors') + ' (' + $t('Skip Guarantor') + ')'"
        />
      </tab-content>

      <!-- lease terms tab -->
      <tab-content :title="$t('Other Terms')">
        <form-lease-terms :terms.sync="leaseFormData.lease" />
      </tab-content>

      <!-- lease terms tab -->
      <tab-content :title="$t('Confirm')">
        <form-lease-confirm :lease.sync="leaseFormData.lease" />
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";

import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import entity from "@/libs/struct/entity";
import address from "@/libs/struct/address";
import { cloneStruct } from "@core/utils/utils";
import { saveToLocal, getLocal, updateArrElemById } from "@/libs/helpers";

import FormAddress from "@/views/forms/components/form-address/FormAddress";
import FormLeaseTerms from "@/views/forms/components/form-leases/FormLeaseTerms";
import FormEntities from "@/views/forms/components/form-entities/FormEntities";
import FormUploadLeaseDocs from "@/views/forms/components/form-leases/FormUploadLeaseDocs.vue";

import FormLeaseConfirm from "@/views/forms/components/form-leases/FormLeaseConfirm";

import Ripple from "vue-ripple-directive";

import laravel from "@/libs/laravel";
import * as init_leases from "@/libs/init_leases";

import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BFormDatepicker,
  BCardText,
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DragDropCloneVue from "@/views/extensions/drag-drop/DragDropClone.vue";
import { sync } from "vuex-pathify";
import { getArrElemById, sortEntitiesArrayByType } from "@/libs/helpers";

let leaseInitData = {
  property_type: "",
  user_id: "",
  lease_type: "",
  address: "",
  // "address": cloneStruct(address),
  description: "",
  terms_type: "",
  start_date: "",
  end_date: "",
  paym_freq: "",
  paym_freq_cnt: "",
  paym_freq_amt: "",
  billing_day: "",
  increaseType: "",
  increaseValue: "",
  bond: "",
  prepay_type: "",
  prepay_value: "",
  latepay_type: "",
  latepay_value: "",
  status: "draft",
  data: "",

  // entities
  leasors: [], // entities
  leasees: [], // entities
  guarantors: [], // entities
  documents: [],
};

export default {
  directives: {
    Ripple,
  },
  components: {
    FormAddress,
    FormEntities,
    FormLeaseTerms,
    FormUploadLeaseDocs,
    FormLeaseConfirm,

    BFormSelect,
    BFormTextarea,
    BCardText,
    BFormDatepicker,

    FormWizard,
    TabContent,

    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      // user: this.$store.state.user.profile,
      // lease: null,
      leaseFormData: {
        // lease object
        lease: JSON.parse(JSON.stringify(leaseInitData)),
      },

      selectedContry: "select_value",
      selectedLanguage: "nothing_selected",
      countryName: [
        { value: "select_value", text: "Select Value" },
        { value: "Russia", text: "Russia" },
        { value: "Canada", text: "Canada" },
        { value: "China", text: "China" },
        { value: "United States", text: "United States" },
        { value: "Brazil", text: "Brazil" },
        { value: "Australia", text: "Australia" },
        { value: "India", text: "India" },
      ],
      languageName: [
        { value: "nothing_selected", text: "Nothing Selected" },
        { value: "English", text: "English" },
        { value: "Chinese", text: "Mandarin Chinese" },
        { value: "Hindi", text: "Hindi" },
        { value: "Spanish", text: "Spanish" },
        { value: "Arabic", text: "Arabic" },
        { value: "Malay", text: "Malay" },
        { value: "Russian", text: "Russian" },
      ],
    };
  },
  props: {
    leaseId: {
      type: Number,
      default: null,
    },
  },
  computed: {
    user: sync("user/profile"),
    leases: sync("leases/list"),
  },
  mounted() {
    console.log("elwv leaeid:", this.leaseId);
    // edit mode
    if (this.leaseId > 0) {
      // find lease object
      // this.lease = getArrElemById(this.leases, "id", this.leaseId);
      // console.log("found lease: ", this.lease);
      // console.log("leaseFormData: ", this.leaseFormData);
      laravel.leases
        .show(this.leaseId)
        .then((resp) => {
          console.log("found edit lease resp: ", resp);
          let leaseObj = JSON.parse(JSON.stringify(resp.data));
          if (leaseObj && leaseObj.user_id != this.user.id) {
            // cannot edit lease that dont below to user
            console.log("user not owner of lease");
            throw "User not owner of lease.";
          }
          let sortedArrs = sortEntitiesArrayByType(leaseObj.entities);
          leaseObj.leasors = sortedArrs.leasors;
          leaseObj.leasees = sortedArrs.leasees;
          leaseObj.guarantors = sortedArrs.guarantors;
          leaseObj.documents = leaseObj.files;
          this.leaseFormData.lease = leaseObj;
          console.log("found edit new formdata: ", this.leaseFormData);
        })
        .catch((error) => {
          console.log("Error: ", error.message);
          // route back toast then route back

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error editing lease.",
              icon: "EditIcon",
              variant: "danger",
            },
          });
          setTimeout(() => this.$router.go(-1), 2000);
        });
    } else {
      // create mode -- temp disabled for demo
      // console.log("mounted load from local: ", getLocal("leaseFormData"));
      // let localdata = getLocal("leaseFormData");
      // if (localdata && localdata.lease) {
      //   this.leaseFormData = localdata;
      // }

      // clear form initially
      saveToLocal("leaseFormData", null);
    }
  },
  methods: {
    resetForm() {
      this.leaseFormData.lease = JSON.parse(JSON.stringify(leaseInitData));
      saveToLocal("leaseFormData", null);
    },
    stepChanged(prevIndex, nextIndex) {
      // console.log("step changed: ", prevIndex, nextIndex);
      saveToLocal("leaseFormData", this.leaseFormData);
    },

    formSubmitted() {
      console.log("user: ", this.user);
      // console.log("leaseFormData:", this.leaseFormData);
      this.submitLease(
        this.user,
        this.leaseFormData,
        this.$toast,
        this.leaseId
      );
      // test1(this.user, this.$toast);
    },

    submitSuccess(lease) {
      // update local data
      console.log("updateArrElembyId lease", lease);
      updateArrElemById(this.leases, "id", lease.id, lease);

      // clear form data
      saveToLocal("leaseFormData", null);

      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Success",
          icon: "EditIcon",
          variant: "success",
        },
      });
    },

    // // helper function to proper format form data for upload
    submitLease(user, formData, toast, leaseId) {
      formData.user_id = user.id;

      if (leaseId > 0) {
        // use update instead.
        laravel.leases
          .update(formData.lease)
          .then((resp) => {
            console.log(resp);
            // clear local storage
            this.submitSuccess(resp.data);

            init_leases.init();
            setTimeout(() => this.$router.go(-1), 1000);
          })
          .catch((error) => {
            console.log(error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + error.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      } else {
        laravel.leases
          .store(formData.lease)
          .then((resp) => {
            console.log(resp);
            // clear local storage
            this.submitSuccess(resp.data);

            init_leases.init();
            setTimeout(
              () => this.$router.push({ name: "manage-leases" }),
              1000
            );
          })
          .catch((error) => {
            console.log(error);
            toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + error.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      }
    },
  },
};

function test1(user, toast) {
  let lease = {
    property_type: "",
    user_id: user.id,
    lease_type: "commercial",
    address: "39 Valentine st yagoona 2199 NSW Australia",
    description: "",
    terms_type: "fixed",
    start_date: "2022-04-29",
    end_date: "2023-04-29",
    paym_freq: "monthly",
    paym_freq_cnt: "",
    paym_freq_amt: "1000",
    billing_day: "1",
    increaseType: "none",
    increaseValue: "",
    bond: "1000",
    prepay_type: "none",
    prepay_value: "",
    latepay_type: "none",
    latepay_value: "",
    status: "draft",
    leasors: [
      {
        type: "individual",
        fullname: "Joe Smith",
        taxid: "",
        entityIdType: null,
        entityId: "",
        address: "asdfasdfsadfasdf",
        phone: "",
        email: "tester1@example.com",
        contact_person: "",
        id: 0,
        prevHeight: -1,
      },
    ],
    leasees: [
      {
        type: "individual",
        fullname: "Smith 2",
        taxid: "",
        entityIdType: null,
        entityId: "",
        address: "asdfasdf",
        phone: "",
        email: "",
        contact_person: "",
        id: 0,
        prevHeight: -1,
      },
      {
        type: "individual",
        fullname: "Smith 3",
        taxid: "",
        entityIdType: null,
        entityId: "",
        address: "",
        phone: "",
        email: "",
        contact_person: "",
        id: 1,
        prevHeight: 0,
      },
    ],
    guarantors: [
      {
        type: "corporate",
        fullname: "Banker 1",
        taxid: "",
        entityIdType: "ABN",
        entityId: "24123123",
        address: "dsfasdfsadf",
        phone: "",
        email: "banker@test.com",
        contact_person: "",
        id: 0,
        prevHeight: -1,
      },
    ],
  };

  submitLease(user, { lease: lease }, toast);
}
</script>
