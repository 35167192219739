<template>
     <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              {{ $t('Address Info')}}
            </h5>
            <small class="text-muted">
              {{ $t('Enter Address')}}
            </small>
          </b-col>

          <b-col md="2">
            <b-form-group
              :label="$t('Unit')"
              label-for="v-address-unit"
            >
              <b-form-input
                v-model="address.unit" 
                id="v-address-unit"
                placeholder="1A"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              :label="$t('Street')"
              label-for="v-address-street"
            >
              <b-form-input
                v-model="address.street" 
                id="v-address-street"
                placeholder="12 Smith St"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              :label="$t('City')"
              label-for="v-address-city"
            >
              <b-form-input
                v-model="address.city" 
                id="v-address-city"
                placeholder="Sydney"
              />
            </b-form-group>
          </b-col>


          <b-col md="4">
            <b-form-group
              :label="$t('Post Code')"
              label-for="v-address-pcode"
            >
              <b-form-input
                v-model="address.postcode" 
                id="v-address-pcode"
                placeholder="2000"
              />
            </b-form-group>
          </b-col>



          <b-col md="4">
            <b-form-group
              :label="$t('State')"
              label-for="v-address-state"
            >
              <b-form-input
                v-model="address.state" 
                id="v-address-pcode"
                placeholder="NSW"
              />
            </b-form-group>
          </b-col>


          <b-col md="4">
            <b-form-group
              :label="$t('Country')"
              label-for="v-address-country"
            >
              <b-form-input
                v-model="address.country" 
                id="v-address-country"
                placeholder="Australia"
              />
            </b-form-group>
          </b-col>



        </b-row>
</template>

<script>

import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect, 
  BFormTextarea, 
  BCardText
} from 'bootstrap-vue'

export default {
    components: {
        BFormSelect,
        BFormTextarea, 
        BCardText,

        BRow,
        BCol,
        BFormGroup,
        BFormInput,

        vSelect
    },
    props: {
        address: {
            type: Object,
            required: true
        }
    }, 
    data() {
        return {
        }
    },
    methods: {

    },
}
</script>
