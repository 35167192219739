<template>
  <!-- <b-card-code title="Repeating Forms"> -->
  <b-row>
    <b-col cols="12" class="mb-2">
      <h5 class="mb-0">
        {{ title }}
      </h5>
      <small class="text-muted">
        {{ subtitle }}
      </small>
    </b-col>
    <!-- temp disabled for demo <b-col v-if="allowSelf" cols="12" class="mb-2">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-primary"
        @click="addSelfAsLeasor()"
      >
        {{ $t("Add me") }}
      </b-button>
    </b-col> -->

    <b-col cols="12" class="mb-2">
      <div class="" title="Entity Entry">
        <div>
          <b-form
            ref="form"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <!-- Row Loop -->
            <b-row
              v-for="(entity, index) in entities"
              :id="entity.id"
              :key="index"
              ref="row"
            >
              <!-- Entity Type -->
              <b-col md="4">
                <b-form-group :label="$t('Entity Type')" label-for="item-type">
                  <b-form-select
                    id="item-type"
                    v-model="entity.type"
                    :options="$t('entityTypeList')"
                    class=""
                  />
                </b-form-group>
              </b-col>

              <!-- Entity Name -->
              <b-col md="8">
                <b-form-group :label="$t('Full Name')" label-for="item-name">
                  <b-form-input
                    id="item-name"
                    v-model="entity.fullname"
                    type="text"
                    placeholder="John Smith"
                  />
                </b-form-group>
              </b-col>

              <!-- Entity address -->
              <b-col md="12">
                <b-form-group
                  :label="$t('Entity Address')"
                  label-for="item-address"
                >
                  <b-form-input
                    id="item-address"
                    v-model="entity.address"
                    type="text"
                    placeholder="1 Smith St, Sydney 2000 NSW Australia"
                  />
                </b-form-group>
              </b-col>

              <!-- Entity Id type -->
              <b-col md="6">
                <b-form-group
                  :label="$t('Entity ID Type')"
                  label-for="item-id-type"
                >
                  <b-form-select
                    id="item-id-type"
                    v-model="entity.entityIdType"
                    :options="$t('entityIdTypeList')"
                    size=""
                    class=""
                  />
                </b-form-group>
              </b-col>

              <!-- Entity Id Value -->
              <b-col md="6">
                <b-form-group
                  :label="$t('Entity ID Value')"
                  label-for="item-id-value"
                >
                  <b-form-input
                    id="item-id-value"
                    v-model="entity.entityId"
                    type="text"
                    placeholder="1242523"
                  />
                </b-form-group>
              </b-col>

              <!-- Entity phone -->
              <b-col md="6">
                <b-form-group :label="$t('Phone')" label-for="item-phone">
                  <b-form-input
                    id="item-phone"
                    v-model="entity.phone"
                    type="text"
                    placeholder="+61 2 9000 8000"
                  />
                </b-form-group>
              </b-col>

              <!-- Entity email -->
              <b-col md="6">
                <b-form-group :label="$t('Email')" label-for="item-email">
                  <b-form-input
                    id="item-email"
                    v-model="entity.email"
                    type="email"
                    placeholder="john@smith.com"
                  />
                </b-form-group>
              </b-col>

              <!-- Remove Button -->
              <b-col lg="2" md="3" class="mb-50 mt-10">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                  <span>Delete</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
          </b-form>
        </div>
        <b-button
          class="mt-20"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="addEntity"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>{{ $t("Add New Entity") }}</span>
        </b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
} from "bootstrap-vue";
// import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { cloneStruct } from "@/@core/utils/utils";
import entity from "@/libs/struct/entity";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import laravel from "@/libs/laravel";

export default {
  components: {
    BFormSelect,
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  // mixins: [heightTransition],
  props: {
    entities: {
      type: Array,
      required: true,
    },
    user: {
      type: Object,
      required: false,
    },
    allowSelf: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      entityType: null,
      entityIdType: null,
      entityIdValue: "",
    };
  },
  mounted() {},
  created() {},
  destroyed() {},
  methods: {
    addEntity() {
      let newEntity = cloneStruct(entity);
      this.entities.push(newEntity);
    },
    removeItem(index) {
      let ent = this.entities[index];
      if (ent.id && ent.id > 0) {
        // remove existing entity from database
        laravel.entities
          .destroy(ent.id)
          .then((resp) => {
            console.log("remove ent: ", resp.data);
            // just remove from form
            this.entities.splice(index, 1);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Entity removed!",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          })
          .catch((error) => {
            console.log("error remove item: ", error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      } else {
        // just remove from form
        this.entities.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
