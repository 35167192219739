<template>
  <div>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">
          {{ $t("Confirm Create") }}
        </h5>
        <small class="text-muted">
          {{ $t("Review and submit") }}
        </small>
      </b-col>

      <!-- general info -->
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-1">
        <small class="mb-0 h3 text-primary">
          {{ $t("General Info") }}
        </small>
        <hr />
      </b-col>

      <b-col md="6">
        <b-form-group
          :label="$t('Type of Property') + ':'"
          label-for="v-property-type"
        >
          <span class="h5 text-uppercase">
            {{ lease.property_type ? lease.property_type : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group
          :label="$t('Type of Lease') + ':'"
          label-for="v-lease-type"
        >
          <span class="h5 text-uppercase">
            {{ lease.lease_type ? lease.lease_type : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <b-form-group :label="$t('Address') + ':'" label-for="v-lease-adress">
          <span class="h5 text-uppercase">
            {{ lease.address ? lease.address : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <span class="h5 text-uppercase">
          {{ lease.description ? lease.description : "None" }}
        </span>
      </b-col>

      <b-col cols="12" class="mt-1">
        <small class="mb-0 h3 text-primary">{{ $t("Terms") }}</small>
        <hr />
      </b-col>

      <b-col md="4">
        <b-form-group
          :label="$t('Lease Type') + ':'"
          label-for="item-lease-type"
        >
          <span class="h5 text-uppercase">
            {{ lease.terms_type ? lease.terms_type : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group :label="$t('Lease Start')" label-for="v-lease-start">
          <span class="h5 text-uppercase">
            {{ lease.start_date ? lease.start_date : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group :label="$t('Lease End')" label-for="v-lease-end">
          <span class="h5 text-uppercase">
            {{ lease.end_date ? lease.end_date : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group
          :label="$t('Payment Frequency')"
          label-for="item-paym-freq"
        >
          <span class="h5 text-uppercase">
            {{ lease.paym_freq ? lease.paym_freq : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group :label="$t('Lease Amount')" label-for="v-lease-amount">
          <span class="h5 text-uppercase">
            {{ lease.paym_freq_amt ? lease.paym_freq_amt : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group :label="$t('Bill Day')" label-for="v-bill-day">
          <span class="h5 text-uppercase">
            {{ lease.billing_day ? lease.billing_day : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group :label="$t('Bond')" label-for="v-bond">
          <span class="h5 text-uppercase">
            {{ lease.bond ? lease.bond : "None" }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="12" class="mt-1">
        <small class="mb-0 h3 text-primary">{{ $t("Lease Documents") }}</small>
        <hr />
      </b-col>

      <b-col cols="12">
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            v-for="file in lease.documents"
            :key="file.name"
          >
            <span class="mr-1 text-success">
              <feather-icon icon="CheckCircleIcon" size="24" />
            </span>
            {{ getNameFromFileObj(file) }}
          </b-list-group-item>
        </b-list-group>
      </b-col>

      <form-entities-summary
        :entities.sync="lease.leasors"
        :allowSelf="true"
        :title="$t('Leasors')"
        :subtitle="$t('Enter leasors information')"
      />

      <form-entities-summary
        :entities.sync="lease.leasees"
        :allowSelf="true"
        :title="$t('Leasees')"
        :subtitle="''"
      />

      <form-entities-summary
        :entities.sync="lease.guarantors"
        :allowSelf="true"
        :title="$t('Guarantors')"
        :subtitle="''"
      />
    </b-row>
    <form-lease-terms-summary :terms.sync="lease" />
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Ripple from "vue-ripple-directive";
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BCard,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

import { getNameFromFileObj } from "@/libs/helpers";

import FormEntitiesSummary from "../form-entities/FormEntitiesSummary";
import FormLeaseTermsSummary from "@/views/forms/components/form-leases/FormLeaseTermsSummary";

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BFormTextarea,
    BCardText,
    BListGroup,
    BListGroupItem,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BCardText,

    FormEntitiesSummary,
    FormLeaseTermsSummary,
  },
  props: {
    lease: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getNameFromFileObj: function (fname) {
      return getNameFromFileObj(fname);
    },
  },
};
</script>
